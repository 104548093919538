<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <div class="a-fs-16 a-fw-b">定价管理</div>
      <div class="a-flex-rcc">
        <le-export-btn :page-param="pageParam"></le-export-btn>
        <el-button icon="el-icon-plus" type="primary" class="s-btn-add a-ml-24" @click="onClickAdd">
          定价
        </el-button>
      </div>
    </div>

    <el-card class="el-main">
      <le-search-form
        id="leSearch"
        :can-pack-up="false"
        @reset="handlerRest"
        @search="handlerSearch"
      >
        <le-company-under-select
          v-model="pageParam.params.companyId"
          label="所属商户"
          placeholder="请选择所属商户"
        ></le-company-under-select>
        <le-input
          v-model="pageParam.params.chargePlanName"
          label="定价名称"
          placeholder="请输入定价名称"
          maxlength="20"
        />
        <le-select-local-search
          v-model="pageParam.params.chargeModel"
          label="计费模式"
          :filterable="false"
          placeholder="请选择计费模式"
          :options="chargeModelList"
        />
        <le-input
          v-model="pageParam.params.updateByName"
          label="更新人"
          placeholder="请输入更新人"
        />
        <le-date-range
          label="更新日期"
          large
          :min-date.sync="pageParam.params.updateStartDate"
          :max-date.sync="pageParam.params.updateEndDate"
        />
      </le-search-form>

      <le-pagview
        :page-param="pageParam"
        :fresh-cache-ctrl="freshCacheCtrl"
        :total.sync="total"
        @setData="setTableData"
      >
        <el-table
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
          @sort-change="onSortChange"
        >
          <el-table-column prop="__number__" label="序号"></el-table-column>
          <el-table-column prop="companyName" label="所属商户"></el-table-column>
          <el-table-column prop="chargeModelLabel" label="计费模式"></el-table-column>
          <el-table-column prop="chargePlanName" label="定价名称"></el-table-column>
          <el-table-column prop="baseText" label="起步费用"></el-table-column>
          <el-table-column prop="overtimeText" label="加时费用"></el-table-column>
          <el-table-column prop="freeChargeDuration" label="免费时长（分钟）"></el-table-column>
          <el-table-column
            prop="usingStationCount"
            label="使用中站点数（个）"
            width="200"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          ></el-table-column>
          <el-table-column prop="updateByName" label="更新人"></el-table-column>
          <el-table-column prop="updateTime" width="180" label="更新时间"></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="{ row }">
              <div class="a-flex-rcc">
                <el-link type="primary" @click.prevent="onClickDetail(row.id)">详情</el-link>
                <el-link type="danger" class="a-ml-10" @click.prevent="onClickDelete(row)">
                  删除
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <template slot="footerLabel">
          <div class="a-flex-rcfe">
            <span class="a-c-666 a-fs-12">筛选出</span>
            <span class="a-fs-16 a-fw-b a-plr-10">{{ total }}</span>
            <span class="a-c-666 a-fs-12">个定价</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import { chargePlanTransform, COMPANY_CHARGE_PLAN_CONST } from "@charge/common";
import { Message } from "element-ui";
import { mapState } from "vuex";

import { companyChargePlanApi } from "@/services/apis/company/charge-plan";
import { eventBus, eventBusKeys } from "@/utils/event-bus";
import util from "@/utils/util.js";

const initParams = {
  companyId: "",
  chargePlanName: "",
  billingModel: "",
  updateByName: "",
  updateStartDate: "",
  updateEndDate: "",
  stationCountOrderType: "",
};
export default {
  name: "PriceList",
  data() {
    this.isFirst = true;
    return {
      chargeModelList: COMPANY_CHARGE_PLAN_CONST.chargeModelList,
      util: util,
      tableData: [],
      freshCacheCtrl: 1,
      pageParam: {
        url: companyChargePlanApi.listURL,
        method: "post",
        params: {
          ...initParams,
        },
        freshCtrl: 1,
      },
      total: 0,
    };
  },

  computed: {
    ...mapState("company", ["company"]),
  },
  watch: {
    company: {
      handler(val) {
        this.pageParam.params.companyId = val.id;
      },
      immediate: true,
    },
  },

  activated() {
    if (this.isFirst) {
      this.isFirst = false;
      return;
    }
    this.freshCacheCtrl++;
  },

  mounted() {
    eventBus.on(eventBusKeys.operatePriceSuccess, this.handleOperatePriceSuccess);
  },

  beforeDestroy() {
    eventBus.off(eventBusKeys.operatePriceSuccess, this.handleOperatePriceSuccess);
  },

  methods: {
    handleOperatePriceSuccess() {
      this.pageParam.freshCacheCtrl++;
    },

    setTableData(data) {
      this.tableData = chargePlanTransform.convertList(data);
    },

    handlerRest() {
      this.pageParam.params = {
        ...initParams,
      };
    },

    handlerSearch() {
      this.pageParam.freshCtrl++;
    },

    onSortChange({ order }) {
      const map = {
        ascending: 2,
        descending: 1,
      };
      this.pageParam.params.stationCountOrderType = map[order];
      setTimeout(() => {
        this.pageParam.freshCtrl++;
      });
    },

    onClickDetail(id) {
      this.$router.push({
        path: "price-detail",
        query: {
          id: id,
        },
      });
    },

    async onClickDelete({ id, usingStationCount }) {
      let content = "是否确定删除该定价？";
      if (usingStationCount) {
        content = "该定价存在使用中的站点，请解除站点的定价后再删除。";
      }
      await this.$confirmModal(content, "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        asyncFn: !usingStationCount
          ? async () => await companyChargePlanApi.delete({ id: id })
          : undefined,
      });
      if (!usingStationCount) {
        this.pageParam.freshCtrl++;
        Message.success("删除成功");
      }
    },

    onClickAdd() {
      this.$router.push("/price/price-add");
    },
  },
};
</script>

<style scoped></style>
